import React from "react";
import SEO from "../components/Seo/seo"
import styled from 'styled-components';
import { theme } from '../utils/theme';
import { responsive } from '../utils/responsive';
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import FooterComponent from "../components/Home/Footer/FooterComponent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { StyledLeftContainer, StyledDescription, StyledButtonWrapper, StyledTitle, StyledLeftContentContainer } from './head';
import { StyledMainVerticalContainer, StyledRightVerticalContainer, StyledVerticalImageWrapper } from './controllers';


// ---- Images ----

import LampImage1 from '../components/imagesStock/Buy/LampImage1';
import LampImage2 from '../components/imagesStock/Buy/LampImage2';
import LampImage3 from '../components/imagesStock/Buy/LampImage3';
import LampImage4 from '../components/imagesStock/Buy/LampImage4';
import LampImage5 from '../components/imagesStock/Buy/LampImage5';
import LampImage6 from '../components/imagesStock/Buy/LampImage6';
import LampImage7 from '../components/imagesStock/Buy/LampImage7';
import LampImage8 from '../components/imagesStock/Buy/LampImage8';
import LampImage9 from '../components/imagesStock/Buy/LampImage9';


// ---- List Styles ----

export const StyledBuyImageWrapper = styled(StyledVerticalImageWrapper)`
    margin: 0 20vw;
    height: 80vh;
    
    ${theme.media.cloud} {
        height: 50%;
    }


    ${theme.media.maxCloud} {

div {
top: 50%;
transform: translateY(-50%);
}

}

    ${theme.media.ultraWide} {
        height: 100%;
 }
`
export const StyledPrice = styled(StyledTitle)`
padding-bottom: 20px;
margin-bottom: 5vh;
`
export const StyledBuyDescription = styled(StyledDescription)`
text-align: center;

h1 {
    border-bottom: 1px solid #c8c7e2;
    display: inline-block;
}
`
export const StyledBuyButtonWrapper = styled(StyledButtonWrapper)`
        justify-content: center;

        a {
            margin-right: 0;
        }

        ${theme.flex.centeredColumn};
        margin-right: 0;
`

const StyledDescriptionButtonWrapper = styled(StyledButtonWrapper)`
justify-content: center;

a {
    ${theme.buttonMain}
    margin-right: 0;
    margin-bottom: 10vh;
}

${theme.media.desktop} {
    ${theme.flex.centeredColumn};
    height: 100%;
    }

${theme.media.mobile} {
text-align: center;
margin-bottom: 0;
}
`

const BuySubpage = () => {

    return (
        <Layout>
            <SEO title="Buy" />

            <StyledMainVerticalContainer>

                <StyledLeftContainer>

                    <StyledLeftContentContainer>

                        <StyledBuyDescription>
                            <h1>Specifications</h1>

                            <p>
                                Height: 195&nbsp;cm <br /><br />
                                Width: 75&nbsp;cm <br /><br />
                                Base diameter: 58&nbsp;cm <br /><br />
                                Weight: 120&nbsp;kg <br /><br />
                                Forward Voltage: 36&nbsp;V <br /><br />
                                Light Colour Temperature: 2700&nbsp;K <br /><br />
                                Typical Luminous Flux: ~&nbsp;12&nbsp;000&nbsp;lm <br /><br />
                            </p>

                        </StyledBuyDescription>

                        <StyledBuyButtonWrapper>
                            <StyledDescriptionButtonWrapper>
                                <div> <a itemProp="url" href="https://www.etsy.com/uk/listing/868895993/floor-lamp-standing-lamp" target="blank" aria-label="etsy">Buy on Etsy</a></div>
                            </StyledDescriptionButtonWrapper>

                            <Link title="This is a link to home subpage" as="a" to="/" aria-label="home"><h1>Back</h1></Link>

                        </StyledBuyButtonWrapper>

                        <FooterComponent />

                    </StyledLeftContentContainer>

                </StyledLeftContainer>

                <StyledRightVerticalContainer>

                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tabletPro", "mobile"]}
                    >

                        <StyledBuyImageWrapper>
                            <LampImage1 />
                        </StyledBuyImageWrapper>

                        <StyledBuyImageWrapper>
                            <LampImage2 />
                        </StyledBuyImageWrapper>

                        <StyledBuyImageWrapper >
                            <LampImage3 />
                        </StyledBuyImageWrapper>

                        <StyledBuyImageWrapper >
                            <LampImage4 />
                        </StyledBuyImageWrapper>

                        <StyledBuyImageWrapper >
                            <LampImage5 />
                        </StyledBuyImageWrapper>

                        <StyledBuyImageWrapper >
                            <LampImage6 />
                        </StyledBuyImageWrapper>

                        <StyledBuyImageWrapper >
                            <LampImage7 />
                        </StyledBuyImageWrapper>

                        <StyledBuyImageWrapper >
                            <LampImage8 />
                        </StyledBuyImageWrapper>

                        <StyledBuyImageWrapper >
                            <LampImage9 />
                        </StyledBuyImageWrapper>

                    </Carousel>


                </StyledRightVerticalContainer>

            </StyledMainVerticalContainer>

        </Layout>
    )
}

export default BuySubpage;